import React from "react";
import { useLenis, ReactLenis } from "lenis/react";
import Logo from "../assets/svg/logo.svg";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
//------------------------------------------------------------------------------
//-- QUERY --
//------------------------------------------------------------------------------
const query = graphql`
  query LayoutQuery {
    menus: menusYaml {
      headerMenu {
        title
        to
      }
    }
    headerMenu: wpMenu(locations: { eq: HEADER_MENU }) {
      menuItems {
        nodes {
          id
          label
          uri
        }
      }
    }
    wp {
      coredoSettings {
        coredoSettingsFields {
          footerSettings {
            quote {
              title
              designation
              content
              cta {
                title
                target
                url
              }
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
            contactLinks {
              title
              link {
                title
                target
                url
              }
            }
          }
        }
      }
    }
  }
`;
//------------------------------------------------------------------------------
//-- LAYOUT --
//------------------------------------------------------------------------------
export default function Layout({ children }) {
  //const lenis = useLenis(({scroll}) => {});
  const layoutData = useStaticQuery(query);
  return (
    <>
      <Header data={layoutData.headerMenu} />
      <ReactLenis root>
        <main>{children}</main>
      </ReactLenis>
      <Footer
        data={layoutData.wp.coredoSettings.coredoSettingsFields.footerSettings}
      />
    </>
  );
}
//------------------------------------------------------------------------------
//-- HEADER --
//------------------------------------------------------------------------------
function Header({ data }) {
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [isNavOpen, setIsNavOpen] = React.useState(false);
  const [isNavFixed, setIsNavFixed] = React.useState(false);

  React.useEffect(() => {
    handleScrollNavPosition();
    window.addEventListener("scroll", handleScrollNavPosition);
  });

  const handleScrollNavPosition = () => {
    const position = window.scrollY;
    const limit = 24;
    setScrollPosition(position);
    if (scrollPosition > limit) {
      return setIsNavFixed(true);
    } else if (scrollPosition < limit) {
      return setIsNavFixed(false);
    }
  };

  return (
    <>
      <header
        className={`fixed top-0 left-0 z-50 w-full py-6 transition-all ${
          isNavFixed ? "bg-white/70 backdrop-blur shadow-xl !py-3" : ""
        }`}
      >
        <div className="o-container !max-w-screen-2xl">
          <div className="flex items-center justify-between w-full">
            <Link className="w-[120px] md:w-[160px] h-auto inline-block" to="/">
              <Logo />
            </Link>
            <ul className="hidden uppercase md:block">
              {data.menuItems.nodes.map((item, idx) => (
                <Link
                  key={`headerMenuItem${idx}`}
                  className="inline-block ml-8 text-[0.75rem] font-medium tracking-wider"
                  activeClassName="is-active"
                  to={item.uri}
                >
                  {item.label}
                </Link>
              ))}
            </ul>
            <button
              className="flex flex-col items-center justify-center md:hidden group"
              onClick={() => setIsNavOpen(!isNavOpen)}
            >
              <div
                className={`w-8 h-1 transition duration-300 transform bg-black rounded-full ease ${
                  isNavOpen
                    ? "rotate-45 translate-y-2 opacity-60 group-hover:opacity-100"
                    : "opacity-60 group-hover:opacity-100"
                }`}
              ></div>
              <div
                className={`w-8 h-1 my-1 transition duration-300 transform bg-black rounded-full ease ${
                  isNavOpen ? "opacity-0" : "opacity-60 group-hover:opacity-100"
                }`}
              ></div>
              <div
                className={`w-8 h-1 transition duration-300 transform bg-black rounded-full ease ${
                  isNavOpen
                    ? "-rotate-45 -translate-y-2 opacity-60 group-hover:opacity-100"
                    : "opacity-60 group-hover:opacity-100"
                }`}
              ></div>
            </button>
          </div>
        </div>
      </header>
      <div
        className={`fixed top-0 bottom-0 left-0 right-0 z-40 items-center justify-center flex overflow-y-auto text-center bg-white/80 backdrop-blur invisible opacity-0 transition-opacity ${isNavOpen ? "!visible !opacity-100" : ""}`}
      >
        <ul className="uppercase">
          {data.menuItems.nodes.map((item, idx) => (
            <Link
              key={`headerMenuItem${idx}`}
              className="block my-3 text-4xl font-medium tracking-wider"
              onClick={() => setIsNavOpen(!isNavOpen)}
              activeClassName="is-active"
              to={item.uri}
            >
              {item.label}
            </Link>
          ))}
        </ul>
      </div>
    </>
  );
}
//------------------------------------------------------------------------------
//-- FOOTER --
//------------------------------------------------------------------------------
function Footer({ data }) {
  const dt = new Date().getFullYear();
  return (
    <footer className="sticky bottom-0 z-0 w-full text-gray-200 bg-slate-800">
      <div className="o-container">
        <h4 className="pt-16 !text-3xl o-title">Let's Talk!</h4>
        <div className="items-end my-12 md:flex md:mt-20 md:mb-32">
          <div className="md:w-2/4">
            <div className="table table-fixed">
              <div className="table-cell w-[64px] md:w-[100px]">
                <GatsbyImage
                  className="rounded-full"
                  image={getImage(
                    data.quote.image.node.localFile.childImageSharp,
                  )}
                />
              </div>
              <div className="table-cell pl-6 pr-12">
                <div className="prose text-gray-200">
                  <h5 className="font-sans text-xl">{data.quote.title}</h5>
                  <h6 className="font-sans text-sm text-gray-400">
                    {data.quote.designation}
                  </h6>
                  <blockquote className="p-0 mt-6 mb-4 font-serif text-xl font-normal tracking-wider text-gray-200 border-0 md:text-2xl">
                    {data.quote.content}
                  </blockquote>
                  <Link className="text-gray-100 o-btn" to={data.quote.cta.url}>
                    {data.quote.cta.title}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="w-12 lg:w-1/4"></div>
          <div className="mt-12 md:w-1/4 lg:mt-0">
            <ul className="text-xl">
              {data.contactLinks.map((item, idx) => (
                <li key={`footerContactLinksItem${idx}`} className="mt-3">
                  <a
                    className="font-medium tracking-wide hover:underline hover:text-red-500"
                    href={item.link.url}
                  >
                    {item.link.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="border-gray-600 border-t-[1px] p-4 pb-6">
          <div className="text-[0.7rem] tracking-wider">
            <div className="text-center">
              &copy;{dt}, Coredo Agency, All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
