exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-work-jsx": () => import("./../../../src/pages/work.jsx" /* webpackChunkName: "component---src-pages-work-jsx" */),
  "component---src-templates-reference-jsx": () => import("./../../../src/templates/reference.jsx" /* webpackChunkName: "component---src-templates-reference-jsx" */),
  "component---src-templates-references-jsx": () => import("./../../../src/templates/references.jsx" /* webpackChunkName: "component---src-templates-references-jsx" */)
}

